import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import FeaturedWork from "../../components/MOB_FeaturedWork"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import TechStack from "../../components/MOB_TechStack"
import SideForm from "../../components/SideForm"
import "../../styles/services.scss"
import "../../styles/animate.css"
import ServiceCard from "../../components/ServiceCard"
import mobileapp_development from '../../images/mobile/mobile_Dev 1.png'
import mobile_design from '../../images/mobile/mobile_design 1.png'
import mobile_testing from '../../images/mobile/mobile_testing 1.png'
import mobile_integration from '../../images/mobile/mobile_integration 1.png'
import deployment_img from '../../images/deployment-01.png'
import behindImageSmall from "../../images/Group 495@2x.png"
import ServiceStepsProcess from "../../components/ServiceStepsProcess"
import IconsAdded from "../../components/IconsAdded"

const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Mobile Development Services"}
    descrip = {"Bring your mobile app ideas to life. Our expert mobile development services deliver high-quality, user-friendly apps for iOS and Android. Contact us today!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/">
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>User Friendly Mobile Applications</Title>
              <br />
              <p>
                We provide state-of-the-art mobile applications to help our
                customers achieve highly accessible and user friendly
                applications. <br /> <br />
                Our focus is to enable our customers with the latest
                technologies and trends to maximize their productivity,
                collaboration, and availability.
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="services-description-section">
      <div className="service-heading-and-detail">
        <Title>Mobile App Development Services</Title>
      </div>
      <div className="service-cards-wrapper">
        <ServiceCard img_src={mobileapp_development} img_alt={'mobileapp_development'} card_heading={'Mobile App Development'} card_para={"Mobile app development is essential in today's digital landscape, enabling businesses to engage users effectively and enhance their services through innovative mobile solutions."}/>
        <ServiceCard img_src={mobile_design} img_alt={'mobile_design'} card_heading={'Mobile App Design'} card_para={'Mobile app design is crucial for creating applications that are not only functional but also enjoyable to use, ultimately leading to higher user satisfaction and retention rates'}/>
        <ServiceCard img_src={mobile_integration} img_alt={'mobile_integration'} card_heading={'Mobile App Integration'} card_para={"Effective mobile app integration is crucial for creating a seamless user experience and maximizing the app's potential in a connected ecosystem."}/>
        <ServiceCard img_src={mobile_testing} img_alt={'mobile_testing'} card_heading={'Mobile App Modernization'} card_para={'Mobile app modernization is essential for maintaining relevance in a fast-paced digital landscape, ensuring that applications continue to meet user needs while leveraging advancements in technology.'}/>
        <ServiceCard img_src={deployment_img} img_alt={'deployment_img'} card_heading={'Post Go Live Support'} card_para={'A critical phase following the launch of a mobile application, ensuring that the app operates smoothly and continues to meet user needs.'}/>
        <ServiceCard card_heading={'Let connect, book a free consultancy session with our experts'} card_button={"Free Consultancy"}/>
      </div>
        <div className="behindimagediv">
            <img
              className="behindImageSmall"
              src={behindImageSmall}
              alt="yellow-triangle"
            />
            <img className="behindImageLarg" src={behindImageSmall} alt="yellow-triangle" />
        </div>
      </div>
      <div className="featured-projects position-relative">
        <FeaturedWork data={data} />
        <IconsAdded/>
      </div>
      <DevelopmentProcess title='Our Mobile App Development process' >
        <ServiceStepsProcess process_heading='Inception & Elicitation' process_para="Talk to the customer Listen to what the customer has to say Brainstorm and prepare project backlog." process_step_number="1" process_indentify="odd" />
        <ServiceStepsProcess process_heading='UI/UX Design' process_para="Create user-friendly interfaces and engaging experiences. This includes wireframing, prototyping, and establishing a cohesive visual style that enhances usability." process_step_number="2"  />
        <ServiceStepsProcess process_heading='Plan sprint' process_para="The team reviews the product backlog and selects items they believe can be completed within the sprint timeframe." process_step_number="3" process_indentify="odd" iconShow="dark"/>
        <ServiceStepsProcess process_heading='Execute Sprint' process_para="The team focuses on completing the items in the sprint backlog, which consists of tasks selected from the product backlog based on priority and team capacity." process_step_number="4" iconShow="dark" />
        <ServiceStepsProcess process_heading='Review' process_para="During this step, the development team showcases the work completed, demonstrating new features and functionalities to the customer." process_step_number="5" process_indentify="odd" />
        <ServiceStepsProcess process_heading='Deployment & Support' process_para="Once testing is complete, the app is released to users through platforms like the Apple App Store or Google Play Store." process_step_number="6" />
      </DevelopmentProcess>
      <TechStack />
    </Layout>
  )
}

export default Index

export const mobileDevelopmentQuery = graphql`
  query mobileDevelopmentQuery {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SNGPL: file(relativePath: { eq: "mobile/SNGPLApp-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SBP: file(relativePath: { eq: "mobile/statebankapp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BigJerry: file(relativePath: { eq: "mobile/BigJerryApp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    UmrahShuttle: file(relativePath: { eq: "mobile/umrahshuttleapp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
