import React from "react"
import { Typography, Card } from "antd"
import { IconContext } from "react-icons"
import { } from "react-icons/fa"
import { SiFlutter, SiAndroid, SiSwift, SiFirebase, SiAndroidstudio, SiXcode, SiAppstore, SiSqlite, SiGoogleplay,
  SiAmazonaws,
  SiMicrosoftazure,
  SiLinux,
  SiNginx,
  SiApache,
  SiDocker} from "react-icons/si"

const { Title } = Typography

const TechStack = () => {
  return (
    <div className="tech-stack">
      <div className="heading">
        <Title>Mobile Development - Technology Stack</Title>
      </div>
      <div className="info-container">
        <div>
          <div className="language">
            <Title level={3}>Language</Title>
            <IconContext.Provider value={{ className: "service-tech-icons" }}>
            <div>
              <SiFlutter />
              <SiAndroid />
              <SiSwift />
            </div>
            </IconContext.Provider>
          </div>
          <div className="database">
            <Title level={3}>Database</Title>
            <IconContext.Provider value={{ className: "service-tech-icons" }}>
            <div>
              <SiSqlite />
              <SiFirebase />
            </div>
            </IconContext.Provider>
          </div>
          <div className="Platform / framework">
            <Title level={3}>Infrastructure</Title>
            <IconContext.Provider value={{ className: "service-tech-icons" }}>
            <div>
              <SiAndroidstudio />
              <SiXcode />
              <SiAppstore />
              <SiGoogleplay />
            </div>
            </IconContext.Provider>
            <div className="second-row">
            <IconContext.Provider value={{ className: "service-tech-icons" }}>
            <div>
              <SiAmazonaws />
              <SiMicrosoftazure />
              <SiLinux />
              <SiNginx />
              <SiApache />
              <SiDocker />
            </div>
            </IconContext.Provider>
            </div>
          </div>
        </div>
        <div style={{ height: "100%" }}>
          <Card style={{ width: "100%", height: "100%" }}>
          <Title level={3}>We have the capability of the most popular programming languages, databases, and infrastructures.</Title>
            <p> We use the most popular programming languages to build mobile applications. We are always ready to adopt new web technologies, 
              to produce futuristic solutions. Currently, we use Flutter, Android, Swift.</p>
            <p>
              Databases are used to store the data in a centralized location. Choosing the right database is vital for the successful run of the application. 
              Currently, we use Firebase and SQLite for most projects.
            </p>
            <p>
             When the application is developed and ready to be deployed for users to use, choosing the suitable infrastructure is important considering the maintenance efforts.
             Currently, we use Android Studio and Xcode to develop applications, and use Amazon aws, Microsofta zure, Linux, Nginx, Nginx and Docker for back end portals in most of our projects.
            </p>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default TechStack
